<template>
  <div class="mb-10 mt-10">
    <v-container no-gutters :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <TitleComponent class="primary--text" title="Announcements"/>
          <div>
            <v-list-item three-line class="pl-1">
              <v-list-item-content>
                <v-list-item-title>Test tittle of item</v-list-item-title>
                <v-list-item-subtitle>
                  Secondary line text Lorem ipsum dolor sit amet,
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  consectetur adipiscing elit.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

             <v-list-item three-line class="pl-1">
              <v-list-item-content>
                <v-list-item-title>Test tittle of item</v-list-item-title>
                <v-list-item-subtitle>
                  Secondary line text Lorem ipsum dolor sit amet,
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  consectetur adipiscing elit.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

             <v-list-item three-line class="pl-1">
              <v-list-item-content>
                <v-list-item-title>Test tittle of item</v-list-item-title>
                <v-list-item-subtitle>
                  Secondary line text Lorem ipsum dolor sit amet,
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  consectetur adipiscing elit.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-btn small plain tile class="mt-5">VIEW MORE</v-btn>
          </div>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <TitleComponent class="primary--text" title="Latest News"/>
          <div class="pl-1">
            <v-card tile elevation="0">
              <v-img
                :aspect-ratio="16/9"
                :src="require('../assets/news.png')"
                tile
              ></v-img>

              <v-card-text class="text-left pl-0 pt-3">
                <h3>Taos pusong pasasalamat ng ating butihing Mayor Valentino "Ben" Patron sa lahat ng mga mamamayan ng San Jose, Batangas</h3>
                <p class="caption">March 25, 2020</p>
              </v-card-text>    
            </v-card>
          </div>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <div class="mt-3">
            <v-list-item three-line class="pl-1">
              <v-list-item-content>
                <v-list-item-title>Test tittle of item</v-list-item-title>
                <v-list-item-subtitle>
                  Secondary line text Lorem ipsum dolor sit amet,
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  consectetur adipiscing elit.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item three-line class="pl-1">
              <v-list-item-content>
                <v-list-item-title>Test tittle of item</v-list-item-title>
                <v-list-item-subtitle>
                  Secondary line text Lorem ipsum dolor sit amet,
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  consectetur adipiscing elit.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item three-line class="pl-1">
              <v-list-item-content>
                <v-list-item-title>Test tittle of item</v-list-item-title>
                <v-list-item-subtitle>
                  Secondary line text Lorem ipsum dolor sit amet,
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  consectetur adipiscing elit.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-btn small plain text class="mt-5">VIEW MORE NEWS</v-btn>
          </div>
        </v-col>
      </v-row>

      
    </v-container>

    <v-divider></v-divider>
  </div>
</template>

<script>
import TitleComponent from '@/components/TitleComponent';
export default {
  name : 'NewsFeeds',
  components : {
    TitleComponent
  }
}
</script>

<style scoped>

</style>